import { Component, OnInit } from '@angular/core';
import { CmsProductCarouselComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

export interface KnBrCmsProductCarouselComponent extends CmsProductCarouselComponent {
  salesOrgs?: string;
}

@Component({
  selector: 'knbr-kn-br-whats-new',
  templateUrl: './kn-br-whats-new.component.html',
  styleUrls: ['./kn-br-whats-new.component.scss']
})
export class KnBrWhatsNewComponent implements OnInit {
  title: string;
  tiles: string;
  constructor(protected componentData: CmsComponentData<any>) {}

  ngOnInit(): void {
    this.componentData.data$.subscribe((data) => {
      this.title = data.title;
      this.tiles = data.tiles;
    });
  }
}
