import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, zip } from 'rxjs';
import { Statuses } from 'src/app/models/kn-br-quote.model';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrQuotesService } from 'src/app/store/kn-br-quotes/kn-br-quotes.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';

import { KnBrCommonService } from '../../../services/kn-br-common.service';
import { KnBrCustomerContextService } from '../../../store/kn-br-customer-context/kn-br-customer-context.service';
import { CustomerData } from './../../../models/customer.model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { ActivatedRoute } from '@angular/router';
import { isEqual } from 'lodash';

@Component({
  selector: 'kn-br-quote-search',
  templateUrl: './kn-br-quote-search.component.html',
  styleUrls: ['./kn-br-quote-search.component.scss'],
  providers: [RemoveLeadingZerosPipe],
})
export class KnBrQuoteSearchComponent implements OnInit, OnDestroy {
  quoteSearchForm: UntypedFormGroup;
  status$: Observable<Statuses[]> = this.knBrCommonService.loadQuoteStatuses$();
  soldToCities$: Observable<any[]> = this.knBrCommonService.loadSoldToCities$();
  validity$: Observable<Statuses[]> = this.knBrCommonService.loadValidityStatuses$();
  isStatusesLoading = false;
  brandSalesShipToList$: Observable<Statuses[]> = this.knBrCommonService.loadBrandSalesShipToList$();
  customersDataList$: Observable<any> = this.knBrCommonService.loadCustomersData$();

  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  selectedCustomer: CustomerData;
  currentPage = 0;
  isSearchDisabled$: Observable<boolean> = this.knBrQuoteService.isSearchDisabled$;
  subscription: Subscription;
  hasDates = false;
  retrievalPending = true;

  hasContext = false;
  // customersDataList = [];
  accountNumber = [];

  //brandList$: Observable<Statuses[]> = this.knBrCommonService.loadActiveSalse$();
  objectKeys = Object.keys;

  todayDate = () => {
    const today = new Date();
    return ({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() });
  };
  maxStartDate = () => this.todayDate();
  minEndDate = () => this.todayDate();
  pBrandName = [];
  pStatus = [];
  pValidity = [];
  pSoldToCity = [];
  pSalesOffice = [];
  pSalesGroup = [];
  state$: Observable<unknown>;
  combinedCallSubscription: Subscription;

  constructor(
    protected fb: UntypedFormBuilder,
    protected knBrQuoteService: KnBrQuotesService,
    protected knBrCommonService: KnBrCommonService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    protected activatedRoute: ActivatedRoute,
    private knBrDateHelper: KnBrDateHelper,
  ) {}

  ngOnInit(): void {
    this.state$ = this.activatedRoute.paramMap
    .pipe(map(() => window.history.state));
    this.subscription = this.customerContextData$.subscribe((value) => {
      if (value) {
        this.hasContext = true;
        this.selectedCustomer = value;
      }
      const customerNumber = value?.customerNumber ? value?.customerNumber : KN_BR_CUSTOMER_CURRENT;
      this.knBrCommonService.getQuoteStatusesList(customerNumber);
      this.knBrCommonService.getQuoteSoldToCityList(customerNumber);
      this.knBrCommonService.getBrandSalesShipToList(customerNumber);
      this.knBrCommonService.getValidityList(customerNumber);
      this.initForm();
    });
    this.state$.subscribe((data:any) => {
      if(data && data.displayPersistedValues) {
        // this.submitForm("persist");
      } else {
        this.submitForm()
      }
    })

    // this.subscription.add(
    //   this.knBrCommonService.customersData$.subscribe(res => {
    //     if (res) {
    //       this.customersDataList = this.removeDuplicates(res);
    //     } else {
    //       this.knBrCommonService.getCustomersData();
    //     }
    //   })
    // );
  }

  removeDuplicates(res) {
    return Array.from(new Set(res));
  }

  initForm() {
    const persistedData = JSON.parse(sessionStorage.getItem("quoteSearch"));
    let flagValue = false;
    this.state$.subscribe((data:any) => {
      if(data && data.displayPersistedValues) {
        flagValue = true;
      } else {
        flagValue = false;
      }
    });
    this.quoteSearchForm = this.fb.group({
      quoteNumber: (flagValue && persistedData?.quoteNumber) ? persistedData.quoteNumber : null,
      accountNumber: [{
        value: this.selectedCustomer && this.selectedCustomer?.customerNumber ? this.knBrCommonService.combineCustomerData(this.selectedCustomer): null,
        disabled: this.selectedCustomer && this.selectedCustomer?.customerNumber ? true : false,
      }],
      // accountName: [{
      //   value: this.selectedCustomer && this.selectedCustomer.customerName ? this.selectedCustomer.customerName : null,
      //   disabled: this.selectedCustomer && this.selectedCustomer.customerName ? true : false,
      // }],
      purchaseOrderNumber: (flagValue && persistedData?.purchaseOrderNumber) ? persistedData.purchaseOrderNumber : null,
      jobName: (flagValue && persistedData?.jobName) ? persistedData.jobName : null,
      status: null,
      productCode: (flagValue && persistedData?.productCode) ? persistedData.productCode : null,
      currentPage: this.currentPage,
      soldToCity: null,
      brandName: null,
      productName: (flagValue && persistedData?.productName) ? persistedData.productName : null,
      salesOffice: null,
      salesGroup: null,
      startDate: null,
      endDate: null,
      _startDate: (flagValue && persistedData?.startDate) ? this.knBrCommonService.formatDate(this.knBrDateHelper.getDate(persistedData?.startDate)) : null,
      _endDate: (flagValue && persistedData?.endDate) ? this.knBrCommonService.formatDate(this.knBrDateHelper.getDate(persistedData?.endDate)) : null,
      pageSize: 50,
      validity: null,
    });
    this.state$.subscribe((data:any) => {
      if(data && data.displayPersistedValues && this.retrievalPending) {
        if(persistedData) {
          const sessionStorageCusNo = sessionStorage.getItem("sscustomerNumber");
          if(this.selectedCustomer?.customerNumber != sessionStorageCusNo && this.retrievalPending) {
            this.retrievalPending = false;
            this.combinedCallSubscription = zip(this.status$, this.validity$, this.soldToCities$, this.brandSalesShipToList$).subscribe((res:any)=> {
              const combinedData = {
                statusData: res[0],
                validityData: res[1],
                soldtoCitiesData: res[2],
                bresp: res[3],
              }
              const {soldtoCitiesData, statusData, validityData, bresp } = combinedData;
                if(persistedData.soldToCity) {
                  persistedData.soldToCity = persistedData.soldToCity.filter(eachSoldToCity => this.containsObject(eachSoldToCity, soldtoCitiesData));
                  this.pSoldToCity = persistedData.soldToCity;
                }
                if(persistedData.status) {
                  persistedData.status = persistedData.status.filter(eachStatus => this.containsObject(eachStatus, statusData));
                  this.pStatus = persistedData.status;
                }
                if(persistedData.validity) {
                  persistedData.validity = persistedData.validity.filter(eachValidity => this.containsObject(eachValidity, validityData));
                  this.pValidity = persistedData.validity;
                }
                if(persistedData.brandName) {
                  persistedData.brandName = persistedData.brandName.filter((eachbrnd) => this.containsObject(eachbrnd, bresp.brand) )
                  this.pBrandName = persistedData.brandName || [];
                }
                if(persistedData.salesGroup) {
                  persistedData.salesGroup = persistedData.salesGroup.filter((eachgrp)=> this.containsObject(eachgrp,bresp.salesGroup))
                  this.pSalesGroup = persistedData.salesGroup;
                }
                if(persistedData.salesOffice) {
                  persistedData.salesOffice = persistedData.salesOffice.filter((eachSalesOffice)=> this.containsObject(eachSalesOffice, bresp.salesOffice));
                  this.pSalesOffice = persistedData.salesOffice;
                }
                this.submitForm("persist")
            })
          } else if(this.retrievalPending) {
            this.retrievalPending = false
            this.pBrandName = persistedData.brandName || [];
            this.pStatus = persistedData.status || [];
            this.pValidity = persistedData.validity || [];
            this.pSoldToCity = persistedData.soldToCity || [];
            this.pSalesOffice = persistedData.salesOffice || [];
            this.pSalesGroup = persistedData.salesGroup || [];
            this.submitForm("newpersist")
          }
        }
      }
    })
    this.setDateValidators();
  }

  setDateValidators() {
    const startDate = this.quoteSearchForm.get('_startDate');
    const enddate = this.quoteSearchForm.get('_endDate');

    startDate.valueChanges.pipe(distinctUntilChanged()).subscribe(fromDate => {
      if (fromDate && enddate.value === null) {
        enddate.setValidators([Validators.required]);
      } else {
        enddate.setValidators(null);
      }
      enddate.updateValueAndValidity();

      if (enddate.value && startDate.value) {
        const { year: y2, month: m2, day: d2 } = enddate.value;
        const e = new Date(y2, m2 - 1, d2);

        const { year: y1, month: m1, day: d1 } = startDate.value;
        const s = new Date(y1, m1 - 1, d1);

        if (s > e) {
          enddate.setValue(null)
        }
      }
    });
    enddate.valueChanges.pipe(distinctUntilChanged()).subscribe(toDate => {
      if (toDate) {
        startDate.setValidators([Validators.required]);
      } else {
        startDate.setValidators(null);
      }
      startDate.updateValueAndValidity();
    });
  }


  submitForm(shouldRetain?) {
    if (this.quoteSearchForm.valid) {
      this.quoteSearchForm.patchValue({
        startDate: this.knBrDateHelper.formatDate(this.quoteSearchForm.value._startDate),
        endDate: this.knBrDateHelper.formatDate(this.quoteSearchForm.value._endDate)
      });
      const { _startDate, _endDate, ...payload } = this.quoteSearchForm.getRawValue();
      const updatedPayload = {...payload};
      const updatedSessionStorage = {...payload};

      if(shouldRetain) {
        updatedSessionStorage.brandName = this.pBrandName;
        updatedSessionStorage.validity = this.pValidity;
        updatedSessionStorage.soldToCity = this.pSoldToCity;
        updatedSessionStorage.status = this.pStatus;
        updatedSessionStorage.salesOffice = this.pSalesOffice;
        updatedSessionStorage.salesGroup = this.pSalesGroup;
        updatedPayload.accountNumber = this.selectedCustomer?.customerNumber;
        updatedPayload.brandName = (this.pBrandName && this.pBrandName.length) ? this.pBrandName.map(eachbrand => eachbrand.value).join(",") : '';
        updatedPayload.validity = (this.pValidity && this.pValidity.length) ? this.pValidity.map(eachValidity => eachValidity.Key).join(","): '';
        updatedPayload.soldToCity = (this.pSoldToCity && this.pSoldToCity.length) ? this.pSoldToCity.map(eachSoldToCity => eachSoldToCity.Key).join(","): ''
        updatedPayload.status = (this.pStatus && this.pStatus.length) ? this.pStatus.map(eachStatus => eachStatus.Key).join(",") : '';
        updatedPayload.salesOffice = (this.pSalesOffice && this.pSalesOffice.length) ? this.pSalesOffice.map(eachSalesOffice => eachSalesOffice.value).join(",") : ''
        updatedPayload.salesGroup = (this.pSalesGroup &&  this.pSalesGroup.length) ? this.pSalesGroup.map(eachSalesGroup => eachSalesGroup.value).join(",") : '';
      }  else {
        updatedPayload.accountNumber = (payload.accountNumber && Array.isArray(payload.accountNumber) && payload.accountNumber.length) ? payload.accountNumber.map(eachacc => eachacc.Key).join(",") : this.selectedCustomer?.customerNumber;
        updatedPayload.brandName = (payload.brandName && payload.brandName.length) ? payload.brandName.map(eachbrand => eachbrand.value).join(",") : '';
        updatedPayload.validity = (payload.validity && payload.validity.length) ? payload.validity.map(eachValidity => eachValidity.Key).join(","): '';
        updatedPayload.soldToCity = (payload.soldToCity && payload.soldToCity.length) ? payload.soldToCity.map(eachSoldToCity => eachSoldToCity.Key).join(","): ''
        updatedPayload.status = (payload.status && payload.status.length) ? payload.status.map(eachStatus => eachStatus.Key).join(",") : '';
        updatedPayload.salesOffice = (payload.salesOffice && payload.salesOffice.length) ? payload.salesOffice.map(eachSalesOffice => eachSalesOffice.value).join(",") : ''
        updatedPayload.salesGroup = (payload.salesGroup &&  payload.salesGroup.length) ? payload.salesGroup.map(eachSalesGroup => eachSalesGroup.value).join(",") : '';
      }
      window.sessionStorage.setItem("quoteSearch",JSON.stringify(updatedSessionStorage));
      window.sessionStorage.setItem("sscustomerNumber", this.selectedCustomer?.customerNumber)
      this.knBrQuoteService.search(updatedPayload);
    }
  }

  containsObject(obj, list) {
    var i;
    for (i = 0; i < list?.length; i++) {
        if (isEqual(list[i], obj)) {
            return true;
        }
    }

    return false;
}

  resetForm() {
    this.quoteSearchForm.reset();
    sessionStorage.removeItem('quoteSearch');
    this.initForm();
    this.submitForm();
    this.clearDateInfo();
  }

  onDateChange(value, param?) {
    this.knBrCommonService.onDateChange(value, param);
  }

  clearDateInfo() {
    this.knBrCommonService.clearDateInfo();
  }

  ngOnDestroy(): void {
    this.clearDateInfo();
    if(this.combinedCallSubscription) {
      this.combinedCallSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
