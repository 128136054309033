<ng-container>
  <div class="col-md-12 p-0 pb-4 float-right d-flex justify-content-between">
    <h3 class="mt-2 font-text-600 titleName">User Profile</h3>
    <!-- <button class="btn back-btn p-1 font-lg-size">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button> -->
  </div><br><br>

  <form *ngIf="profileDetailsForm" [formGroup]="profileDetailsForm">
    <div></div>
    <div class="col-md-12 float-left customersearch-forms login-forms customer-home-forms mb-1">
      <!-- <span class="pl-0">Personal Information</span> -->
      <div>
        <div class="form-group mt-3 col-lg-3 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.firstName' | cxTranslate" formControlName="firstName"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.firstName' | cxTranslate }}</label>
        </div>

        <div class="form-group mt-3 col-lg-3 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.lastName' | cxTranslate" formControlName="lastName"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.lastName' | cxTranslate }}</label>
        </div>

        <div class="form-group mt-3 col-lg-3 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.preferredName' | cxTranslate" formControlName="preferredName"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.preferredName' | cxTranslate }}</label>
        </div>

        <div class="form-group mt-3 col-lg-3 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.businessEmail' | cxTranslate" formControlName="businessEmail"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.businessEmail' | cxTranslate }}</label>
        </div>
      </div>

      <div>
        <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.role' | cxTranslate" formControlName="role"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.role' | cxTranslate }}</label>
        </div>

        <!-- <div class="form-group mt-3 col-lg-3 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.customerGroup' | cxTranslate" formControlName="customerGroup"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.customerGroup' | cxTranslate }}</label>
        </div> -->

        <div class="form-group mt-3 col-lg-2 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.businessPhone' | cxTranslate" formControlName="businessPhone"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.businessPhone' | cxTranslate }}</label>
        </div>

        <div class="form-group mt-3 col-lg-1 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.extension' | cxTranslate" formControlName="extension"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.extension' | cxTranslate }}</label>
        </div>

        <div class="form-group mt-3 col-lg-3 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.businessCell' | cxTranslate" formControlName="businessCell"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.businessCell' | cxTranslate }}</label>
        </div>

        <!-- <div class="form-group mt-3 col-lg-3 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.mobileNumber' | cxTranslate" formControlName="mobileNumber"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.mobileNumber' | cxTranslate }}</label>
        </div> -->

        <div class="form-group mt-3 col-lg-3 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.alternateCell' | cxTranslate" formControlName="alternateCell"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.alternateCell' | cxTranslate }}</label>
        </div>

        <!-- <div class="form-group mt-3 col-lg-3 pl-0 float-left p-res-0">
          <input type="text" class="form-control w-100" [placeholder]="'KnbrProfile.label.alternateMobileNumber' | cxTranslate" formControlName="alternateMobileNumber"/>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'KnbrProfile.label.alternateMobileNumber' | cxTranslate }}</label>
        </div> -->

          <div class="row mb-5">
            <div class="col-md-12 float-left customersearch-forms login-forms sold-to-list pl-3 customer-home-forms mb-1">
              <h4 class="pl-0 font-text-400 mb-4 mt-4">Associated Sold-To Accounts</h4>

              <div class="customersearch-list-table float-left col-md-12 pl-0">
                <div class="customersearch-list-titles border-top float-left w-100 p-2">
                  <div class="col-md-2 float-left">
                    Payer #
                  </div>
                  <div class="col-md-3 float-left font-text-400 font-md-size pl-0">
                    Payer Name
                  </div>
                  <div class="col-md-2 float-left font-text-400 font-md-size pl-0">
                    Account #
                  </div>
                  <div class="col-md-3 float-left font-text-400 font-md-size pl-0">
                    Account Name
                  </div>
                  <div class="col-md-2 float-left font-text-400 font-md-size pl-0">
                    Location
                  </div>
                </div>
                <div class="bg-white table-content border-top float-left w-100 p-1" *ngFor="let customer of this.customerListData; let i = index">
                  <div class="col-md-2 float-left mr-0">
                    <span class="">{{ customer.payerId }}</span>
                  </div>
                  <div class="col-md-3 form-check-inline mr-0">
                    <span class="">{{ customer.payerName }}</span>
                  </div>
                  <div class="col-md-2 form-check-inline mr-0">
                    <span class="">{{ customer.customerNumber }}</span>
                  </div>
                  <div class="col-md-3 form-check-inline mr-0">
                    <span class="">{{ customer.customerName }}</span>
                  </div>
                  <div class="col-md-2 form-check-inline mr-0">
                    <span class="">{{ customer.addressLine2 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div class="col-lg-12 p-0 float-left mb-3">
          <div class="col-lg-2 p-0 m-0 mr-3 float-right">
            <button class="w-100 mt-1 mt-sm-0 btn btn-outline-primary" (click)="goTo()">
              {{ 'KnbrProfile.button.RequestProfileUpdate' | cxTranslate }}
            </button>
          </div>

          <span class="float-right col-lg-7 mt-3 mr-3 text-right">Note: Click 'Request Profile Update' to make changes to your profile; you'll be redirected to 'Ask Us'</span>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-8 float-left customersearch-forms login-forms pl-3 customer-home-forms mb-1">
        <h4 class="pl-0 font-text-400">Notification Details</h4>

        <div class="customersearch-list-table float-left col-md-8 pl-0">
          <div class="customersearch-list-titles border-top float-left w-100 p-2">
            <div class="col-md-7 float-left">
              Notification Type <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
            </div>
            <div class="col-md-2 float-left font-text-400 font-md-size pl-0">
              Email
            </div>
            <div class="col-md-2 float-left font-text-400 font-md-size pl-2">
              Text
            </div>
          </div>
          <div class="bg-white table-content border-top float-left w-100 p-1" *ngFor="let notification of this.notificationListData; let i = index">
            <div class="col-md-7 float-left">
              <span class="">{{ notification.notificationType }}</span>
            </div>
            <div class="col-md-2 form-check-inline">
              <input type="checkbox" class="form-check-input" (change)="select(notification.notificationMode, i)" [checked]=""/>
            </div>
            <div class="col-md-2 form-check-inline">
              <input type="checkbox" class="form-check-input" (change)="select(notification.notificationMode, i)" [checked]=""/>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  </form>
  <!-- <div class="row justify-content-end my-3">
    <div class="col-md-2 pl-0 p-res-0 float-left">
      <button type="submit" class="w-100 btn btn-primary">
        {{ 'button.cancel' | cxTranslate }}
      </button>
    </div>

    <div class="col-md-2 pl-0 p-res-0 float-left">
      <button type="button" class="w-100 btn btn-outline-primary">
        {{ 'button.save' | cxTranslate }}
      </button>
    </div>
  </div> -->
</ng-container>
