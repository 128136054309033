// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASEURL: 'https://api.cq7pbmgz94-carlislec1-s1-public.model-t.cc.commerce.ondemand.com',
  SSO: "https://accstorefront.cq7pbmgz94-carlislec1-s1-public.model-t.cc.commerce.ondemand.com/samlsinglesignon/saml2/authenticate/external-id",
  externalSSO: "https://accstorefront.cq7pbmgz94-carlislec1-s1-public.model-t.cc.commerce.ondemand.com/samlsinglesignon/saml2/authenticate/external-id",
  PREFIX: '/occ/v2/',
  BASE_SITE: ['carlisle-customer', 'carlisle-customer-ca'],
  COUNTRY: ['us', 'ca'],
  ORIGIN: 'api.cq7pbmgz94-carlislec1-s1-public.model-t.cc.commerce.ondemand.com',
  gtmId: 'GTM-Test',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
