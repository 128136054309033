import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { KnBrWhatsNewComponent } from './kn-br-whats-new.component';
import { CarouselModule, MediaModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CarouselModule,
    MediaModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CarlisleWhatsNewComponent: {
          component: KnBrWhatsNewComponent,
        },
      },
    } as CmsConfig),
  ]
})
export class KnBrWhatsNewModule { }
