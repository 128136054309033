<cx-page-slot position="Section1"></cx-page-slot>
<span class="welcome-msg" *ngIf="userName">Hello {{userName}}!</span>
<cx-page-slot position="Section2"></cx-page-slot>
<cx-page-slot position="Section3"></cx-page-slot>
<div class="slideshow-container" >
  <cx-page-slot position="Section4" #carouselSopt> </cx-page-slot>
  <div *ngIf="totalSlides > 0">
    <button class="carousel__prev-button" [disabled]="slideIndex === 1" (click)="plusSlides(-1)">
      <cx-icon [type]="previousIcon"></cx-icon>
    </button>
    <button
      class="carousel__next-button"
      [disabled]="slideIndex === totalSlides || totalSlides === 0"
      (click)="plusSlides(1)"
    >
      <cx-icon [type]="nextIcon"></cx-icon>
    </button>
  </div>
</div>
<ng-container *ngIf="totalSlides > 0">
  <div class="dots" *ngIf="dots.length > 0">
    <span
      *ngFor="let dot of dots; let i = index"
      class="dot"
      [ngClass]="{ active: i + 1 === slideIndex }"
      (click)="totalSlides > 1 && currentSlide(i + 1)"
    ></span>
  </div>
</ng-container>
<cx-page-slot position="Section5"></cx-page-slot>
